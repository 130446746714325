div ::-webkit-scrollbar {
  height: 10px; /* Keep height for vertical scrollbar */
  width: 12px; /* Adjust the width for horizontal scrollbar */
  background-color: #f5f5f5;
}

div ::-webkit-scrollbar-thumb {
  background-color: #979595;
  width: 12px; /* Adjust the width for the scrollbar thumb */
}

.react-datetime-picker__wrapper {
  border: thin solid #dddddd;
  border-radius: 5px;
}
.react-calendar {
  border-color: #dddddd;
  border-radius: 5px;
  margin-top: 10px;
}

.branch-classList {
  max-height: 300px;
  overflow-y: auto;
  height: 100%;
}
.schema-selection {
  display: inline-block;
  border: 1px solid #c4c3c3;
  border-radius: 4px;
  padding: 8px 35px 8px 10px;
  background-color: #fcf9f961;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  max-width: 180px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
.schema-selection svg {
  /* margin-left: auto; */
}

.schema-selection:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.schema-selection:hover {
  background-color: #eeeeee;
}
.select-schema-list {
  height: 300px;
  overflow-y: auto;
}
.schema-list-item {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  margin: 0 !important;
  font-size: 15px;
}
.schema-list-item:hover {
  background: #f5f5f5;
  cursor: pointer;
}
:root {
  --background-light: #ffffff; /* Light mode background color */
  --text-color-light: #000000; /* Light mode text color */
  --background-dark: #1a202c; /* Dark mode background color */
  --text-color-dark: #ffffff; /* Dark mode text color */
}

/* Rounding CSS */
.rounding-radio {
  display: flex;
  border-radius: 5px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  font-size: 11px;
}

.rounding-radio input[type='radio'],
.rounding-radio .chakra-radio__control {
  position: absolute;
  opacity: 0;
}

.rounding-radio label {
  flex: 1;
  text-align: center;
  cursor: pointer;
  justify-content: center;
}

.rounding-radio .chakra-radio__label {
  margin: 0;
  width: 100%;
  display: block;
  padding: 5px 0;
  font-size: 12px;
}

.rounding-radio label:not(:last-child) {
  border-right: none;
}

.rounding-radio label:hover {
  background-color: #f0f0f0;
  color: #000;
}

.rounding-radio
  .chakra-radio__input:checked
  + .chakra-radio__control
  + .chakra-radio__label {
  background-color: #edf2f7;
  color: #000;
  font-weight: 600;
}

.chakra-ui-dark
  .rounding-radio
  .chakra-radio__input:checked
  + .chakra-radio__control
  + .chakra-radio__label {
  background-color: var(--background-dark);
  color: var(--text-color-dark);
  font-weight: 600;
}

.chakra-ui-dark .react-calendar {
  background-color: var(--background-dark);
  color: var(--text-color-dark);
}

.chakra-ui-dark .rounding-radio label:hover {
  background-color: var(--background-dark);
  color: var(--text-color-dark);
}

.chakra-ui-dark .rounding-radio {
  border-color: var(--background-dark);
}

.chakra-ui-dark .react-calendar__navigation button:disabled,
.chakra-ui-dark .react-calendar__tile:disabled,
.chakra-ui-dark .react-calendar__tile:enabled:hover,
.chakra-ui-dark .react-calendar__tile:enabled:focus,
.chakra-ui-dark .react-calendar__navigation button:enabled:hover,
.chakra-ui-dark .react-calendar__navigation button:enabled:focus {
  background-color: #535353;
  color: var(--text-color-dark);
}

.chakra-ui-dark .react-datetime-picker__inputGroup {
  color: var(--text-color-dark);
}

/* Click to search product */
.click-to-search-product {
  position: relative;
}

.click-to-search-product .list-wrapper {
  min-height: 38px;
  height: auto;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding-right: 35px;
}

.click-to-search-product .list-wrapper .list-item {
  margin: 2px 2px;
  border-radius: 3px;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 2px 5px;
  height: auto;
  line-height: normal;
  min-height: 20px;
  height: auto;
}

.click-to-search-product .list-wrapper .open-list {
  margin-left: auto;
  min-width: auto;
  padding: 5px;
  height: 20px;
  border-radius: 0;
}

.click-to-search-product .search-wrapper {
  position: absolute;
  opacity: 0;
}

.click-to-search-product .search-wrapper .product-list {
  padding: 10px 0px;
  margin: 0 !important;
}

.click-to-search-product .search-wrapper .product-list li {
  margin: 0 !important;
  padding: 0px 10px;
  font-size: 14px;
}

.product-list li:hover {
  font-weight: 600;
}

.open-search-product {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
}

.open-search-product button {
  display: flex;
  height: 100% !important;
}

.react-dropdown-tree-select .dropdown {
  max-width: 250px;
  width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  width: 100%;
  padding: 10px;
}

.chakra-ui-light .react-dropdown-tree-select {
  max-width: 400px;
  width: auto;
}

.chakra-ui-light .react-dropdown-tree-select .dropdown .dropdown-trigger {
  border-color: #e2e8f0;
  border-radius: 4px;
}

.chakra-ui-light .tag-item .search {
  border: 0;
}

.infinite-scroll-component .toggle {
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  width: 10px;
  display: inline-block;
}

.react-dropdown-tree-select .dropdown {
  text-align: left;
}
.custom-dropdown {
  max-width: 50%;
}
.custom-dropdown ul::-webkit-scrollbar,
.custom-dropdown::-webkit-scrollbar {
  height: 4px; /* Keep height for vertical scrollbar */
  width: 6px; /* Adjust the width for horizontal scrollbar */
  background-color: #f5f5f5;
}

.custom-dropdown ul::-webkit-scrollbar-thumb,
.custom-dropdown::-webkit-scrollbar-thumb {
  background-color: #979595;
  width: 6px; /* Adjust the width for the scrollbar thumb */
}

.ag-row-dragging {
  opacity: 0.5; /* Reduce opacity during drag */
  transition: transform 0.2s ease-in-out; /* Animation for movement */
}
.react-datetime-picker {
  width: 100%;
}
.react-datetime-picker .react-datetime-picker__wrapper {
  border: 0;
  border-bottom: 1px solid gainsboro;
  border-radius: 0;
  padding-bottom: 5px;
}
